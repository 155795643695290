import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";

interface HeaderProps {
  title: string;
  handleInstagramLogout?: () => void;
}

export const Header: React.FC<HeaderProps> = ({ title, handleInstagramLogout = null }) => (
  <Root>
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: 10
      }}
    >
      <BrandIcon src="/images/relatable_circle.svg" alt="brand_logo" />
      <Title>{title}</Title>
      {handleInstagramLogout ? (
        <LogoutButton onClick={() => handleInstagramLogout()}>Logout</LogoutButton>
      ) : null}
    </div>
    <p style={{ width: "100%", fontSize: 10, textAlign: "center", margin: 0 }}>
      Find content approval guide
      <a
        style={{ padding: "0 3px" }}
        target="_blank"
        rel="noreferrer"
        href="https://intercom.help/relatable/en/articles/6401776-content-approval-process"
      >
        here.
      </a>
    </p>
  </Root>
);

const Root = styled.header`
  width: 100%;
  max-width: 400px;
  background: #fffffe;
  position: fixed;
  top: 0;
  z-index: 2;
  padding-top: 15px;
  padding-bottom: 16px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10;
  align-items: center;
`;

const BrandIcon = styled.img`
  width: 30px;
  height: 30px;
`;

const Title = styled.a`
  text-decoration: none;
  color: ${palette.gray[90]};
  font-weight: 700;
  line-height: 1.7;
`;

const LogoutButton = styled.button`
  text-decoration: none;
  color: ${palette.gray[60]};
  width: auto;
  text-align: center;
  margin-right: 15px;
  font-size: 0.8rem;
  font-weight: 700;
  border: 0;
  padding: 0;
  background-color: transparent;
  cursor: pointer;
`;
