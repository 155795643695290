import { useEffect } from "react";

import { NetworkStatus } from "@apollo/client";
import styled from "@emotion/styled";
import { palette } from "@relatable/ui/Palette";
import { useLocation } from "react-router-dom";

import { Loader } from "@relatable/ui/Loader";
import { Header } from "components/Layout/Header";
import { UserCard } from "components/Layout/UserCard";
import { useUser } from "components/hooks/useUser";

import { ContentCard } from "./ContentCard/ContentCard";
import { useContentApprovalListQuery } from "./generated";

export const ContentList: React.FC = () => {
  const user = useUser();

  const location = useLocation();
  const {
    data: {
      campaign: [campaign] = []
    } = {},
    loading: getContentApprovalListLoading,
    refetch,
    networkStatus
  } = useContentApprovalListQuery();

  // biome-ignore lint/correctness/useExhaustiveDependencies: we refetch when location changes
  useEffect(() => {
    refetch();
  }, [location, refetch]);

  if (getContentApprovalListLoading || networkStatus === NetworkStatus.refetch) {
    return <Loader />;
  }

  if (!campaign || !user) {
    return null;
  }

  const contactEmail = campaign.account?.email ?? "product@relatable.me";

  return (
    <>
      <Header title="Submit content for approval" />
      <Root>
        <Title>{campaign.title}</Title>
        <Brandname>
          In association with{" "}
          {campaign.project.hubspot_deals.map(deal => deal.client.name).join(", ")}
        </Brandname>
        <UserCard
          style={{ marginBottom: 16 }}
          profilePicture={user.profilePicture ?? ""}
          username={user.username ?? ""}
          platformUrl={user.platformUrl}
          platform={user.platform ?? undefined}
        />

        <CardsContainer>
          {campaign.campaign_content_settings
            .filter(i => {
              if (!user) return false;
              if (!Array.isArray(user.excluded_content_setting_ids))
                throw Error("r.excluded_content_setting_ids should be an array");
              return !user.excluded_content_setting_ids.includes(i.id);
            })
            .map((content, index) => (
              <ContentCard
                key={content.id}
                editLocation={`${location.pathname}/${content.id}`}
                content={content}
                platform={campaign.platform}
                index={
                  campaign.campaign_content_settings
                    .slice(0, index)
                    .filter(s => s.type === content.type).length
                }
                indexTotal={
                  campaign.campaign_content_settings.filter(s => s.type === content.type).length
                }
              />
            ))}
        </CardsContainer>

        <ContactInfo>
          Find content approval guide{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://intercom.help/relatable/en/articles/6401776-content-approval-process"
          >
            here
          </a>{" "}
          or contact us at <a href={`mailto:${contactEmail}`}>{contactEmail}</a>
        </ContactInfo>
      </Root>
    </>
  );
};

const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  padding-top: 15px;
  width: 100%;
`;

const Title = styled.span`
  font-weight: 700;
  font-size: 24px;
  margin-top: 16px;
`;

const Brandname = styled.span`
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 500;
  color: ${palette.gray[60]};
`;

const CardsContainer = styled.div`
  align-self: center;
  display: flex;
  width: calc(100%);
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`;

const ContactInfo = styled.span`
  font-size: 0.7rem;
  margin-top: 10px;
  margin-bottom: 20px;
`;
