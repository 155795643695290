import type { Project_Client_Review } from "@relatable/gql/generated-base";

export const CONTENT_RIGHT_PERIODS = <const>{
  "48h": {
    value: "48h",
    label: "48 hours"
  },
  "1w": {
    value: "1w",
    label: "1 week"
  },
  "2w": {
    value: "2w",
    label: "2 weeks"
  },
  "3w": {
    value: "3w",
    label: "3 weeks"
  },
  "6w": {
    value: "6w",
    label: "6 weeks"
  },
  "10w": {
    value: "10w",
    label: "10 weeks"
  },
  "1m": {
    value: "1m",
    label: "1 month"
  },
  "2m": {
    value: "2m",
    label: "2 months"
  },
  "3m": {
    value: "3m",
    label: "3 months"
  },
  "4m": {
    value: "4m",
    label: "4 months"
  },
  "5m": {
    value: "5m",
    label: "5 months"
  },
  "6m": {
    value: "6m",
    label: "6 months"
  },
  "10m": {
    value: "10m",
    label: "10 months"
  },
  "12m": {
    value: "12m",
    label: "12 months"
  },
  "16m": {
    value: "16m",
    label: "16 months"
  },
  "2y": {
    value: "2y",
    label: "2 years"
  },
  "3y": {
    value: "3y",
    label: "3 years"
  },
  in_perpetuity: {
    value: "in_perpetuity",
    label: "In perpetuity"
  },
  hide: {
    value: "hide",
    label: "Hide 🙅",
    variant: "danger"
  }
};

export enum CONTENT_APPROVAL_STATE {
  CREATOR_INITIAL_UPLOAD = "CREATOR_INITIAL_UPLOAD",
  MANAGER_REVIEWING = "MANAGER_REVIEWING",
  MANAGER_REJECTED = "MANAGER_REJECTED",
  CLIENT_REVIEWING = "CLIENT_REVIEWING",
  CLIENT_REJECTED = "CLIENT_REJECTED",
  CLIENT_APPROVED = "CLIENT_APPROVED",
  READY_FOR_PUBLISHING = "READY_FOR_PUBLISHING"
}

export const BUDGET_CATEGORIES = {
  INFLUENCER_MARKETING_YT: {
    value: "influencer_marketing_youtube",
    label: "Influencer marketing - YouTube"
  },
  INFLUENCER_MARKETING_IG: {
    value: "influencer_marketing_instagram",
    label: "Influencer marketing - Instagram"
  },
  INFLUENCER_MARKETING_TT: {
    value: "influencer_marketing_tiktok",
    label: "Influencer marketing - TikTok"
  },
  INFLUENCER_MARKETING_SC: {
    value: "influencer_marketing_snapchat",
    label: "Influencer marketing - Snapchat"
  },
  LIVE_SHOPPING: {
    value: "live_shopping",
    label: "Live shopping"
  },
  AMPLIFY: { value: "amplify", label: "Amplify" },
  CONTENT_RIGHTS: { value: "content_rights", label: "Content rights" },
  EVENT_PR: { value: "event/pr", label: "Event/PR" },
  LOGISTICS: { value: "logistics", label: "Logistics" },
  PURCHASES: { value: "purchases", label: "Purchases" },
  OTHER: { value: "other", label: "Other" }
} as const;

export const CONTENT_TYPES = {
  VIDEO: { value: "video", label: "Reel" },
  PHOTO: { value: "photo", label: "Feed - Photo" },
  CAROUSEL: { value: "carousel", label: "Feed Carousel - Video or Photo" },
  STORY: { value: "story", label: "Story - Video or Photo" },
  TIKTOK: { value: "tiktok", label: "TikTok" },
  YOUTUBE: { value: "youtube", label: "YouTube" },
  SNAP: { value: "snap", label: "Snap" }
};

export const BITLY_DOMAINS = [
  "bitly.com",
  "bit.ly",
  "adobe.ly",
  "go90.show",
  "rlauren.co",
  "ubr.to",
  "philips.to",
  "amzn.to",
  "thd.co",
  "apple.co",
  "yloils.eu",
  "gtx.to",
  "ava.st"
] as const;

export const TIMELINE_GROUP_WEIGHT = [25, 25, 25, 25];

export const CLIENT_REVIEW_FIELDS: {
  title: string;
  desc: string;
  answers: string[];
  textInput?: boolean;
  id: keyof Project_Client_Review;
}[] = [
  {
    id: "rate_satisfaction",
    title: "Overall Satisfaction",
    desc: "How satisfied are you with the outcome of the campaign?",
    answers: ["Very Dissatisfied", "Dissatisfied", "Neutral", "Satisfied", "Very Satisfied"]
  },
  {
    id: "rate_selection",
    title: "Influencer Selection",
    desc: "Did the influencers selected align well with your brand and campaign objectives?",
    answers: ["Not at all", "Not really", "Somewhat", "Yes, perfectly"]
  },
  {
    id: "rate_execution",
    title: "Campaign Execution",
    desc: "How would you rate the overall execution of the campaign?",
    answers: ["Poor", "Fair", "Good", "Excellent"]
  },
  {
    id: "rate_communication",
    title: "Communication",
    desc: "How satisfied were you with the communication throughout the campaign?",
    answers: ["Very dissatisfied", "Dissatisfied", "Neutral", "Satisfied", "Very satisfied"]
  },
  {
    id: "rate_results",
    title: "Results",
    desc: "Did the campaign meet your expectations in terms of engagement, reach, and/or conversions?",
    answers: ["Did not meet expectations", "Met expectations", "Exceeded expectations"]
  },
  {
    id: "rate_recommendation",
    title: "Likelihood to Recommend",
    desc: "How likely are you to recommend Relatable to others for influencer marketing campaigns?",
    answers: ["Very Unlikely", "Unlikely", "Neutral", "Likely", "Very Likely"]
  },
  {
    id: "rate_work_again",
    title: "Likelihood to Work Again",
    desc: "How likely are you to work with Relatable for another influencer marketing campaign?",
    answers: ["Very Unlikely", "Unlikely", "Neutral", "Likely", "Very Likely"]
  },
  {
    id: "area_of_improvements",
    title: "Areas of Improvement",
    desc: "Please provide any specific areas where you think we could improve our services or any additional comments you may have.",
    answers: [],
    textInput: true
  },
  {
    id: "comments",
    title: "Additional Comments",
    desc: "Please feel free to share any additional comments or suggestions for future campaigns.",
    answers: [],
    textInput: true
  }
];
