import { type FC, useEffect } from "react";

import { Button } from "@relatable/ui/Button";
import { Loader } from "@relatable/ui/Loader";
import { palette } from "@relatable/ui/Palette";
import { usePrevious } from "@relatable/ui/hooks/usePrevious";
import { useLocation, useNavigate } from "react-router-dom";

import { useEncodedUser } from "./useEncodedUser";
import { useRedirector } from "./useRedirector";

export const AgreementStep: FC = () => {
  const { user } = useEncodedUser();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const prevIsAgreementSigned = usePrevious(Boolean(user?.isAgreementSigned));

  useEffect(() => {
    if (prevIsAgreementSigned === false && user?.isAgreementSigned) {
      navigate(pathname.replace("/agreement", "/payment"), { replace: true });
    }
  }, [prevIsAgreementSigned, user?.isAgreementSigned, navigate, pathname]);

  const { isLoaded } = useRedirector();
  if (!isLoaded) return <Loader />;

  if (!user?.isAgreementReady && !user?.isAgreementSigned) {
    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 10 }}>
        <h1>The agreement is not ready yet.</h1>
        <p>Please contact your Production Manager.</p>
      </div>
    );
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 10 }}>
      <p style={{ margin: 0 }}>Agreement</p>

      <p style={{ marginBottom: 0 }}>
        You will be redirected to Scrive where your agreement waits for you.
      </p>
      <p style={{ marginTop: 0 }}>Once you’ve signed it, we will start the campaign process!</p>

      <Button
        variant="outlined"
        disabled={!user?.agreementUrl}
        onClick={() => {
          if (!user?.agreementUrl) return;
          window.open(user?.agreementUrl, "_blank")?.focus();
        }}
        style={{ marginTop: 10, background: palette.gray.white }}
        size="medium"
      >
        Go to agreement
      </Button>

      <Button href={pathname.replace("/agreement", "/payment")} disabled={!user?.isAgreementSigned}>
        All done!
      </Button>
    </div>
  );
};
