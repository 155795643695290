import styled from "@emotion/styled";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import LinkIcon from "@mui/icons-material/Link";
import { palette } from "@relatable/ui/Palette";
import { Link } from "react-router-dom";

export const BriefTip: React.FC<{
  briefUrl: string;
}> = ({ briefUrl }) => {
  return (
    <>
      <Tip>
        {briefUrl ? (
          <StyledLink to={briefUrl} target="_blank" rel="noreferrer">
            View brief
            <LinkIcon />
          </StyledLink>
        ) : (
          "Check the brief for more info"
        )}
      </Tip>
      <Tip>
        <StyledLink
          to={"https://intercom.help/relatable/en/articles/6401776-content-approval-process"}
          rel={"noreferrer"}
          target="_blank"
        >
          Find content approval guide here
          <ArticleIcon />
        </StyledLink>
      </Tip>
    </>
  );
};

const Tip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5;
  text-align: center;
  font-size: 14px;
  color: ${palette.gray[60]};
  font-weight: 700;
  max-width: 300px;
  margin: 10px auto;
`;

const StyledLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  text-align: center;
`;
